import initialState from "./initialState";
import { TICKET_ACTION_TYPE } from "../../constants/actionTypes";

export default function headerReducer(state = initialState.ticket, action) {
  switch (action.type) {

    case TICKET_ACTION_TYPE.FETCH_LIST_SUCCESS:
      return {
        ...state,
        listTickets: action.data
      }
    case TICKET_ACTION_TYPE.FECTCH_PAY_URL_SUCCESS:
      return {
        ...state,
        payUrl: action.data
      }
    case TICKET_ACTION_TYPE.VERIFY_PAYMENT:
      return {
        ...state,
        verifyPayment: action.data,
        isVnpayReturn: true
      }
    case TICKET_ACTION_TYPE.UPDATE_TICKET:
      return {
        ...state,
        billInfo: {
          ...state.billInfo,
          tickets: action.data,
          totalPrice: action.data.total
        }
      }
    case TICKET_ACTION_TYPE.UPDATE_INFO:
      return {
        ...state,
        billInfo: {
          ...state.billInfo,
          ...action.data
        }
      };
    case TICKET_ACTION_TYPE.UPDATE_PAYID:
      return {
        ...state,
        payId: action.data
      }
    case TICKET_ACTION_TYPE.UPDATE_TICKET_DETAIL:
      return {
        ...state,
        ticketDetail: {
          ...state.ticketDetail,
          ...action.data
        }
      }
    case TICKET_ACTION_TYPE.CLEAR_ALL:
      return initialState.ticket;
    case TICKET_ACTION_TYPE.IS_PROCESSING:
      return {
        ...state,
        isProcessing: true
      };
    case TICKET_ACTION_TYPE.PROCESSING_DONE:
      return {
        ...state,
        isProcessing: false
      };
    case TICKET_ACTION_TYPE.SHOW_ERROR:
      return {
        ...state,
        error: action.data
      };
    case TICKET_ACTION_TYPE.SET_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.data
      };
    default:
      return state;
  }
}