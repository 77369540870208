import BaseService from "./baseService";
import ticketApi from "../api/ticketApi";

class TicketService extends BaseService {
  getAllListTickets = async () => {
    try {
      return await ticketApi.getAllListTicket();
    } catch (e) {
      this.handleError(e);
    }
  }

  placeOrder = async (data) => {
    try {
      return await ticketApi.placeOrder(data);
    } catch (e) {
      this.handleError(e);
    }
  }

  getPayUrl = async (data) => {
    try {
      return await ticketApi.getPayUrl(data);
    } catch (e) {
      this.handleError(e);
    }
  }

  verifyPayment = async (data) => {
    try {
      return await ticketApi.verifyPayment(data);
    } catch (e) {
      this.handleError(e);
    }
  }

  orderDetail = async (data) => {
    try {
      return await ticketApi.orderDetail(data);
    } catch (e) {
      this.handleError(e);
    }
  }
}

export default new TicketService();
