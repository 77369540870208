export const LOCALE_ACTION_TYPE = {
  CHANGE_LOCATE_SUCCESS: "CHANGE_LOCATE_SUCCESS"
};

export const HOME_ACTION_TYPE = {
  SHOW_POPUP: "SHOW_POPUP",
  SHOW_POPUP_TICKET: "SHOW_POPUP_TICKET",
  SHOW_POPUP_INPUT: "SHOW_POPUP_INPUT",
  SHOW_POPUP_DETAIL: "SHOW_POPUP_DETAIL",
  CLOSE_ALL: "CLOSE_ALL"
};

export const TICKET_ACTION_TYPE = {
  FETCH_LIST_SUCCESS: "FETCH_LIST_TICKET_SUCCESS",
  FECTCH_PAY_URL_SUCCESS: "FECTCH_PAY_URL_SUCCESS",
  VERIFY_PAYMENT: "VERIFY_PAYMENT",
  SUBMIT_INFO: "SUBMIT_INFO",
  UPDATE_TICKET: "UPDATE_TICKET",
  UPDATE_INFO: "UPDATE_INFO",
  UPDATE_PAYID: "UPDATE_PAYID",
  UPDATE_TICKET_DETAIL: "UPDATE_TICKET_DETAIL",
  PAY: "PAY",
  CLEAR_ALL: "CLEAR_ALL",
  IS_PROCESSING: "IS_PROCESSING",
  PROCESSING_DONE: "PROCESSING_DONE",
  SHOW_ERROR: "SHOW_ERROR",
  SET_ERROR_MSG: "SET_ERROR_MSG"
}
