import { Route, Switch } from "react-router-dom";
import React from "react";
import VnpayReturn from './PopupTicket/VnpayReturn';
import TicketPage from "./Ticket";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/vnpay-return" component={VnpayReturn} />
        <Route exact path="/" component={TicketPage} />
      </Switch>
      <ToastContainer />
    </>
  );
}
export default App;
