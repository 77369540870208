export default {
  popup: {
    show: {
      ticket: false,
      input: false,
      detail: false
    },
    optionType: 0
  },
  ticket: {
    listTickets: [],
    billInfo: {
      first_name: '',
      last_name: '',
      email: '',
      birthday: '',
      mobile: '',
      tickets: {},
      totalPrice: 0
    },
    ticketDetail: {
      "id": null,
      "customer_email": null,
      "customer_first_name": null,
      "customer_last_name": null,
      "customer_birthday": null,
      "customer_phone_number": null,
      "payment_status": null,
      "payment_date": null,
      "order_items": []
    },
    isProcessing: false,
    payId: null,
    payUrl: '',
    verifyPayment: false,
    isVnpayReturn: false,
    error: false,
    errorMsg: ''
  }
};
