import React from "react";

const PopupSuccessCod = () => {
  return (
    <div className="popup_ct result_ct">
      <div className="result_sucess">
        <p className="result_logo">
          <img src="./images/logo_mkcs.png" alt="Logo" />
        </p>
        <h2>
          <span className="bg"><span>Đăng ký nhận vé qua bưu điện<br/></span> thành công</span>
        </h2>
        <p>Vui lòng kiểm tra thông tin vé tại <span className="bg">email</span> bạn đã đăng ký</p>
        <p className="result_txt"><span>BTC sẽ xác nhận với bạn qua email trong vòng</span> 2 ngày làm việc <br/><span>kể từ thời điểm nhận được thanh toán mua vé và phí vận chuyển</span></p>
      </div>
    </div>
  );
};

export default PopupSuccessCod;
