import { HOME_ACTION_TYPE } from "../../constants/actionTypes";
import initialState from "./initialState";
import objectAssign from "object-assign";

export default function homeReducer(state = initialState.popup, action) {
  switch (action.type) {
    case HOME_ACTION_TYPE.SHOW_POPUP:
      return objectAssign({}, state, {
        show: action.show,
        optionType: action.optionType
      });
    case HOME_ACTION_TYPE.SHOW_POPUP_TICKET:
      return {
        ...state,
        show: {
          ticket: action.isShow,
          input: false,
          detail: false
        }
      }
    case HOME_ACTION_TYPE.SHOW_POPUP_INPUT:
      return {
        ...state,
        show: {
          ticket: false,
          input: action.isShow,
          detail: false
        }
      }
    case HOME_ACTION_TYPE.SHOW_POPUP_DETAIL:
      return {
        ...state,
        show: {
          ticket: false,
          input: false,
          detail: action.isShow
        }
      }
    case HOME_ACTION_TYPE.CLOSE_ALL:
      return initialState.popup;
    default:
      return state;
  }
}
