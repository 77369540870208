// BUILD TYPES
const dev = {};
dev.buildMode = "DEV";
dev.serverSchema = "http";
dev.serverHost = process.env.REACT_APP_APIHOST; // 'api.server.dev';

const staging = {};
staging.buildMode = "DEV";
staging.serverSchema = "https";
staging.serverHost = "chatapi.redhotpie.com.au"; // 'api.server.dev';

const production = {};
production.buildMode = "PRODUCTION";
production.serverSchema = "http";
production.serverHost = process.env.REACT_APP_APIHOST;

//
const buildApp = (build) => {
  switch (build.buildMode) {
    case dev.buildMode:
      return dev;
    case staging.buildMode:
      return production;
    case production.buildMode:
      return production;
    default:
      return dev;
  }
};

const getBuildType = () => {
  if (process.env.NODE_ENV === "staging") {
    return staging;
  } else if (process.env.NODE_ENV === "production") {
    return production;
  }
  return dev;
};

const currentBuild = getBuildType();

export default {
  buildTypes: {
    dev,
    staging,
    production,
    isProduction: (currentBuild.buildMode === production.buildMode),
    isStaging: (currentBuild.buildMode === staging.buildMode),
    isDevelop: (currentBuild.buildMode === dev.buildMode)
  },
  build: buildApp(currentBuild)
};
