import React from "react";

const PopupSuccess = () => {
  return (
    <div className="popup_ct result_ct">
      <div className="result_sucess">
        <p className="result_logo">
          <img src="./images/logo_mkcs.png" alt="Logo" />
        </p>
        <h2>
          <span className="bg"><span>Bạn đã</span> đăng ký mua vé thành công</span>
        </h2>
        <p>Vui lòng kiểm tra thông tin vé tại <span className="bg">email</span> bạn đã đăng ký</p>
        <p className="result_txt">Địa chỉ nhận vé và thanh toán:<br/><span>23/1A Nơ Trang Long, P. 7, Q. Bình Thạnh, TP. HCM</span><br/>Thời gian nhận vé: <span>Từ 20/06/2024<br/>10:00 đến 17:00 (Thứ 2 đến Thứ 6)</span></p>
      </div>
    </div>
  );
};

export default PopupSuccess;
