import { TICKET_ACTION_TYPE } from "../../constants/actionTypes";
import TicketService from "../../services/ticketService";
import format from 'date-fns/format';

const fectListTicketSuccess = (data) => {
  return {
    type: TICKET_ACTION_TYPE.FETCH_LIST_SUCCESS,
    data
  };
}

const fecthPayUrlSuccess = (data) => {
  return {
    type: TICKET_ACTION_TYPE.FECTCH_PAY_URL_SUCCESS,
    data
  };
}

const verifyPayment = (data) => {
  console.log(data)
  return {
    type: TICKET_ACTION_TYPE.VERIFY_PAYMENT,
    data
  };
}

const updateTicket = data => {

  return {
    type: TICKET_ACTION_TYPE.UPDATE_TICKET,
    data
  };
}

const updateInfo = data => {
  return {
    type: TICKET_ACTION_TYPE.UPDATE_INFO,
    data
  };
}

const clearAll = () => {
  return {
    type: TICKET_ACTION_TYPE.CLEAR_ALL
  };
}

const processing = () => {
  return {
    type: TICKET_ACTION_TYPE.IS_PROCESSING
  };
};

const processingDone = () => {
  return {
    type: TICKET_ACTION_TYPE.PROCESSING_DONE
  };
}

const updatePayId = (data) => {
  return {
    type: TICKET_ACTION_TYPE.UPDATE_PAYID,
    data
  }
}

const updateTicketDetail = (data) => {
  return {
    type: TICKET_ACTION_TYPE.UPDATE_TICKET_DETAIL,
    data
  }
}

const showError = (data) => {
  return {
    type: TICKET_ACTION_TYPE.SHOW_ERROR,
    data
  }
}

const getAllListTickets = () => {
  return async (dispatch) => {
    const res = await TicketService.getAllListTickets();
    const newList = res['data']['items'].map((item, index) => {
      let obj = {
        id: index,
        name: item.name,
        enable: item.enable,
        valid: false,
        price: item.price,
        imageName: item.name.toString().replace(/\s/g, '').toLowerCase(),
      };

      if (item.enable) {
        const curDate = new Date();
        const validDate = new Date(item.validDate);

        if (curDate > validDate) {
          obj['valid'] = true;
        }

        obj['isSoldOut'] = item['count'] >= item['max'];
      }

      return obj;

    });

    dispatch(fectListTicketSuccess(newList));
  }
}

const placeOrder = (customerInfo) => {
  return async (dispatch, prevState) => {
    dispatch(processing());
    dispatch(updateInfo(customerInfo));

    const ticketReducer = { ...prevState().ticketReducer };

    const { billInfo } = ticketReducer;

    const tickets = billInfo.tickets;
    const listSubmitTicket = Object.keys(tickets).reduce((rev, key) => {
      if (key !== 'total') {
        rev.push({
          "name": tickets[key].name,
          "qty_ordered": tickets[key].count,
          "price": tickets[key].price,
          "base_price": tickets[key].price,
          "total": tickets[key].total
        });
      }

      return rev;
    }, []);
    const submitData = {
      "customer_email": customerInfo.email,
      "customer_first_name": customerInfo.first_name,
      "customer_last_name": customerInfo.last_name,
      "customer_birthday": format(customerInfo.birthday, 'yyyy/MM/dd'),
      "customer_phone_number": customerInfo.phone,
      "grand_total": billInfo.totalPrice,
      "sub_total": billInfo.totalPrice,
      "order_items": listSubmitTicket
    }

    const res = await TicketService.placeOrder(submitData);

    if (res.statusCode === 200) {
      if (res.data !== "out-of-service") {
        dispatch(updatePayId(res.data.id));
      } else {
        dispatch(showError(true));
      }
    }
    dispatch(processingDone());
  }
}

const getPayUrl = () => {
  return async (dispatch, prevState) => {
    dispatch(processing());
    const ticketReducer = { ...prevState().ticketReducer };
    const redirectUrl = `${window.location.origin}/vnpay-return`;

    const params = {
      "transactionAmount": ticketReducer.billInfo.totalPrice,
      "targetId": ticketReducer.payId,
      "redirectUrl": redirectUrl
    };

    const res = await TicketService.getPayUrl(params);

    if (res.statusCode === 200) {
      dispatch(fecthPayUrlSuccess(res.data.url));
    }

    dispatch(processingDone());
  }
}

const verifyPaymentAction = (urlParams) => {
  return async (dispatch) => {
    // const res = await TicketService.verifyPayment(urlParams);

    // if (res.data.code === '00') {
    dispatch(verifyPayment(urlParams));
    // } else {
    //     dispatch(verifyPayment(false));
    // }
  }
}

const getOrderDetail = (payId) => {
  return async (dispatch, prevState) => {


    const res = await TicketService.orderDetail({
      orderId: payId
    });

    if (res.statusCode === 200) {
      dispatch(updateTicketDetail(res.data));
    }
  }
}

export {
  getAllListTickets,
  updateTicket,
  updateInfo,
  clearAll,
  processing,
  processingDone,
  placeOrder,
  updatePayId,
  getPayUrl,
  verifyPaymentAction,
  getOrderDetail,
  showError,
  updateTicketDetail
}
