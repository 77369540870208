import React, { Component, Fragment, Suspense } from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import App from "./views/App";
import { Provider as ReduxProvider } from "react-redux";
import theme from "./theme";

import 'react-toastify/dist/ReactToastify.css';

export default class Root extends Component {
  render() {
    const { store } = this.props;
    return (
      <ReduxProvider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <Suspense fallback={<Fragment />}>
              <App />

            </Suspense>
          </MuiThemeProvider>
        </BrowserRouter>

      </ReduxProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};
