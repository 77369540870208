import { combineReducers } from "redux";
import homeReducer from "./homeReducer";
import ticketReducer from "./ticketReducer";

const rootReducer = history =>
  combineReducers({
    homeReducer,
    ticketReducer
  });

export default rootReducer;
