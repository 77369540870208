export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePass(pass) {
  const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return passw.test(pass);
}

export function validateNumberPhone(number) {
  let c1 = number.substring(0, 4);
  if (c1 === "+855") {
    return true;
  }
  c1 = number.substring(0, 3);
  return c1 === "+60" || c1 === "+84";
}

export function isObjectUndefinedOrNull(obj) {
  return obj === undefined || obj === null;
}

export function isEmpty(obj) {
  return obj === undefined || obj === null || obj === "";
}

export function getHostUrl() {
  return process.env.REACT_APP_SITE;
}

export function checkPassword(passwordInput) {
  const defaultPassword = process.env.REACT_APP_PASSWORD;
  return passwordInput === defaultPassword;
}

export function getTimeStamp() {
  const ts = process.env.REACT_APP_TIMESTAMP;

  const arrTs = ts.toString().split("_");

  return `${arrTs[0]} ${arrTs[1]}:00`;
}

export function numberToCurrency(number) {
  return new Intl.NumberFormat('vn', { style: 'currency', currency: 'VND' }).format(number);
}

export function gotoSection(idSection) {
  window.location.href = `${window.location.origin}#${idSection}`;
}

export function number_to_phone(v) {
  if (v === 0) { return '0'; }
  v = v.toString();
  v = v.split(' ').join('');

  v = v.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");

  return v;
}