import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ticketActions } from "../../redux/actions";

const VnpayReturn = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const searchParam = new URLSearchParams(location.search);
    let params = {};

    searchParam.forEach((value, key) => {
      params[key] = value;
    });

    dispatch(ticketActions.verifyPaymentAction(params));

    // if (params['vnp_OrderInfo']) {
    //   const vnpOrderInfo = params['vnp_OrderInfo'];
    //   const orderId = vnpOrderInfo.slice(vnpOrderInfo.indexOf('<') + 1, vnpOrderInfo.indexOf('>'));
    //   dispatch(ticketActions.updatePayId(orderId));
    // }
    props.history.push('/', { dataParams: params })

  }, [location.search]);

  return (
    <></>
    // <Redirect to="/" done={true} />
  )
}

export default VnpayReturn;