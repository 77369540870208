import BaseApi from "./baseApi";

class TicketApi extends BaseApi {
  getAllListTicket = () => {
    return super.execute(this.Methods.GET, this.Urls.ticket.listTicket, null, false, null);
  }

  placeOrder = (data) => {
    return super.execute(this.Methods.POST, this.Urls.ticket.placeOrder, null, false, data);
  }

  getPayUrl = data => {
    return super.execute(this.Methods.POST, this.Urls.ticket.payUrl, null, false, data);
  }

  verifyPayment = data => {
    return super.execute(this.Methods.POST, this.Urls.ticket.verifyPayment, null, false, data);
  }

  orderDetail = (data) => {
    return super.execute(this.Methods.POST, this.Urls.ticket.orderDetail, null, false, data);
  }
}

export default new TicketApi();
