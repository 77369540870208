import React, { useState, useEffect, useRef } from "react";
import { number_to_phone, validateEmail } from './../../utils/common'
import './ticket.css'
import cookie from "react-cookies";
import { toast } from 'react-toastify'
import moment from 'moment'
import TicketService from "../../services/ticketService";
import { useSelector } from 'react-redux'
import PopupSuccess from "views/PopupSuccess";
import PopupMomo from "views/PopupMomo";
import PopupSuccessCod from "views/PopupSuccessCod";

const HOME_PAGE_URL = "https://rockfest.vn/"

function TicketPage(props) {
    const [count, setCount] = useState(0)
    const [countBig, setCountBig] = useState(0)
    const [data, setData] = useState({})
    const [errorObject, setError] = useState({})
    const redirectRef = useRef()
    const [isRegister, setIsRegister] = useState()
    const [isAccept, setIsAccept] = useState()
    const [order, setOrder] = useState({})
    const [isPaid, setIsPaid] = useState()
    const ticketReducer = useSelector(state => state.ticketReducer);
    const { isVnpayReturn } = ticketReducer;
    let dataParams = {}
    if (props.location.state) {
        dataParams = props.location.state.dataParams
    }

    useEffect(() => {
        if (isVnpayReturn) {
            handleResultWhenPay(dataParams)
            setTimeout(() => {
                window.location.href = HOME_PAGE_URL
            }, 15000)
        } else {
            cookie.remove('targetId')
            window.$('.select-quantity').toggleClass('open');
            window.$('body').toggleClass('active');
        }
    }, [])

    async function handleResultWhenPay(dataParams) {
        if (dataParams && dataParams.vnp_TxnRef) {
            TicketService.orderDetail({
                transactionCode: dataParams.vnp_TxnRef
            }).then((res) => {
                if (res.statusCode === 200) {
                    if (res.data && res.data.payment_status === "paid") {
                        setOrder(res.data)
                        setIsAccept(true)
                    } else {
                        setIsAccept(false)
                    }
                } else {
                    // toast.error("Giao dịch của bạn đã hủy")
                    setIsAccept(false)
                    // handleChangeStep()
                }
            });
        } else {
            setIsAccept(false)
            setIsRegister(false)
            handleChangeStep()
        }

    }

    function onChangeData(value, name) {
        data[name] = value
        errorObject[name] = ""
        setData({ ...data })
        setError({ ...errorObject })
    }

    let isPass = true

    if (!data.name || data.name === "") {

        isPass = false
    }

    if (!data.phone || data.phone === "") {

        isPass = false
    }

    if (!validateEmail(data.email)) {

        isPass = false
    }

    if (data.email !== data.vertifyEmail) {

        isPass = false
    }

    if (!data.email || data.email === "") {

        isPass = false
    }

    if (!data.isAccept) {

        isPass = false
    }

    if (!data.vertifyEmail || data.vertifyEmail === "") {

        isPass = false
    }

    if (!data.payment || data.payment === "") {

        isPass = false
    }

    function setDown(prev) {
        if (!prev) {
            return 0
        } else {
            prev--
            return prev
        }
    }

    function setUp(prev) {
        if (!prev) {
            return 1
        } else if (prev > 98) {
            return 99
        } else {
            prev++
            return prev
        }
    }

    function onChangeCount(e, cb) {
        let newValue = e.target.value
        while (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)
        }
        if (newValue > 98) {
            newValue = 99
        }
        cb(newValue)
    }

    function handleChangeStep(e) {
        e && e.preventDefault();
        if (!count && !countBig && e) {
            toast.error("Số vé phải trên 0")
        } else {
            window.$('.select-quantity').toggleClass('open');
            window.$('.payment').toggleClass('open');
        }
    }

    function validate() {
        let isPass = true
        const error = {}
        if (!data.name || data.name === "") {
            error.name = "Trường này bắt buộc"
            isPass = false
        }

        if (!data.phone || data.phone === "") {
            error.phone = "Trường này bắt buộc"
            isPass = false
        }

        if (!validateEmail(data.email)) {
            error.email = "Email không hợp lệ"
            isPass = false
        }

        if (data.email !== data.vertifyEmail) {
            error.vertifyEmail = "Xác nhận email không khớp"
            isPass = false
        }

        if (!data.email || data.email === "") {
            error.email = "Trường này bắt buộc"
            isPass = false
        }

        if (!data.vertifyEmail || data.vertifyEmail === "") {
            error.vertifyEmail = "Trường này bắt buộc"
            isPass = false
        }

        if (!data.payment || data.payment === "") {
            error.payment = "Trường này bắt buộc"
            isPass = false
        }

        if (data.payment === "cod" && (!data.customer_address || data.customer_address === "")) {
          error.customer_address = "Trường này bắt buộc"
          isPass = false
        }

        setError(error)

        return isPass
    }

    async function handlePayment() {
        const total = count * 300000
        const total2 = countBig * 1500000
        const newData = {
            customer_email: data.email,
            customer_first_name: data.name,
            customer_last_name: " ",
            customer_birthday: moment().format("DD/MM/YYYY"),
            customer_phone_number: data.phone,
            shipping_method: data.payment,
            customer_address: data.payment === "cod" ? data.customer_address: undefined,
            order_items: []
        }

        if (count > 0) {
            newData.order_items.push({
                name: "VÉ BỈ NGẠN HỒNG",
                qty_ordered: count
            })
        }
        if (countBig > 0) {
            newData.order_items.push({
                name: "ROCKBAND",
                qty_ordered: countBig
            })
        }

        const result2 = await TicketService.placeOrder(newData)
        if (result2.statusCode === 200) {
            if (data.payment === 'vnpay') {
                const params = {
                    "transactionAmount": total + total2,
                    "targetId": result2.data.id
                };
                cookie.save('targetId', result2.data.id)

                TicketService.getPayUrl(params).then(res => {
                    setIsRegister(false)
                    if (res.statusCode === 200) {
                        setCountBig(0)
                        setCount(0)
                        setData({
                            name: "",
                            email: "",
                            vertifyEmail: "",
                            phone: "",
                            payment: "",
                            isAccept: false
                        })
                        if (res.data) {
                            redirectRef.current.href = res.data.url;
                            redirectRef.current.click();
                        }
                    } else {
                        toast.error(res.message || "Đã có lỗi xảy ra")
                    }
                })
            } else {
                setIsRegister(data.payment)
                setIsPaid(true)
                window.$('.payment').toggleClass('open');
                setCountBig(0)
                setCount(0)
                setData({
                    name: "",
                    email: "",
                    vertifyEmail: "",
                    phone: "",
                    payment: "",
                    isAccept: false
                })
                if (data.payment !== "cod") {
                    setTimeout(() => {
                        window.location.href = HOME_PAGE_URL
                    }, 15000)
                }
            }
        } else {
            toast.error(result2.message || "Đã có lỗi xảy ra")
        }
    }
   
    return (
        <div className="ticket_container">
      {(isVnpayReturn || isPaid) && (
        <>
          {
            isRegister ? (
              <>
                {/* check xem momo hay offline */}
                <div className="result popup_ct payment" style={{ background: 'none' }}>
                  {isRegister === 'cod' ? <PopupSuccessCod /> : <PopupSuccess/>}
                  <p onClick={() => window.location.href = HOME_PAGE_URL} className={`btn_accept`}>Trang chủ</p>
                </div>
              </>
            ) : <div className="result popup_ct">
              {
                isAccept ? <>
                  <p className="big"><img src="./images/ic_rock_on.png" alt="Done" />GIAO DỊCH CỦA BẠN ĐÃ THÀNH CÔNG!</p>
                  <p>Vui lòng kiểm tra Email (Hoặc Spam mail) <br className="pc" />để nhận vé điện tử tham gia chương trình <br className="pc" />ROCKFEST vào ngày 12/03/2022.</p></> : <>
                  <p>GIAO DỊCH THẤT BẠI!</p>
                  <p>Mã đơn hàng: {cookie.load("targetId") || order.id}</p>

                </>
              }
              <p onClick={() => window.location.href = HOME_PAGE_URL} className={`btn_accept`}>Trang chủ</p>
            </div>
          }
        </>
      )
      }

      <div className="select-quantity popup">
        <div className="popup_bg"/>
        <ul className="box_ticket_list aos-init aos-animate" data-aos="fade-up">
          <li>
            <div className="info">
              <p className="name">VÉ <span>BỈ NGẠN HỒNG</span></p>
              <p className="bonus">
                <span><span>1</span>Vé tham dự chương trình</span>
              </p>
              <p className="bonus">
                <span><span>1</span>Quà tặng "dưỡng thê"</span>
              </p>
              <p className="bonus">
                <span><span>1</span>Vòng tay ROCKFEST</span>
              </p>
            </div>
            <div className="price">
              <p>
                <span className="real" data-label="600k/ 1 vé">600k/ 1 vé</span>
              </p>
              <div className="number">
                <span
                  className="minus"
                  onClick={() => {
                    setCount(setDown)
                  }}
                >-</span>
                <span className="amount">
                  <input
                    type="number"
                    onChange={(e) => {
                      onChangeCount(e, setCount)
                    }}
                    value={count}
                    min={0} max={20}
                    defaultValue={0}
                  />
                </span>
                <span
                  className="plus"
                  onClick={() => {
                    setCount(setUp)
                  }}
                >+</span>
              </div>
            </div>
          </li>
        </ul>
        <div className="btn_wrap">
            <p className="btn_prev" data-aos="fade-up"><a href="https://rockfest.vn/">Quay lại</a></p>
            <p className="btn_next" data-aos="fade-up" onClick={handleChangeStep}>Tiếp theo</p>
        </div>
        <p className="center"><img src="./images/ticket_banner.png" alt="Banner" width={500}/></p>
      </div>

      <div className="payment popup">
        <div className="popup_bg"></div>
        <div className="popup_ct">  
          <p className="popup_logo">
            <img src="./images/logo_mkcs.png" alt="Logo" />
          </p>
          <div className="step_1">
            <div className="popup_form">
              <p>
                <span>Phương thức<br />nhận vé*</span>
                <select value={data.payment}
                  onChange={(e) => {
                    const { value } = e.target
                    onChangeData(value, 'payment')
                  }} name="payment" id="payment">
                   <option value="" className="option">Chọn phương thức nhận vé</option>
                   <option value="offline" className="option">Nhận vé trực tiếp tại văn phòng</option>
                  <option value="cod" className="option">Nhận vé qua bưu điện</option>
                  {/* <option value="momo" className="option">Thanh toán qua Momo</option> */}
                </select>
                {
                  errorObject && errorObject.payment && errorObject.payment !== "" ? (<div className="error">{errorObject.payment}</div>) : null
                }
              </p>
              
              <p>
                <span>Họ Và Tên*</span><input value={data.name} onChange={(e) => {
                  const { value } = e.target
                  const newValue = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                  onChangeData(newValue, 'name')
                }} type="text" placeholder="Ví dụ : Nguyễn Văn A" />

                {
                  errorObject && errorObject.name && errorObject.name !== "" ? (<div className="error">{errorObject.name}</div>) : null
                }
              </p>

              <p><span>Số điện thoại*</span><input onChange={(e) => {
                const { value } = e.target
                let newValue = value
                if (newValue.length > 2) {
                  const text = newValue.substring(0, 3);
                  const second = newValue.substring(3, newValue.length);
                  newValue = text + number_to_phone(second)
                }
                onChangeData(newValue, 'phone')
              }} value={data.phone} type="text" placeholder="Ví dụ : 028 9999 44 88" />
                {
                  errorObject && errorObject.phone && errorObject.phone !== "" ? (<div className="error">{errorObject.phone}</div>) : null
                }
              </p>
              <p><span>Email*</span><input onChange={(e) => {
                const { value } = e.target
                onChangeData(value, 'email')
              }} type="text" value={data.email} placeholder="Ví dụ : rockfestvietnam@gmail.com" />
                {
                  errorObject && errorObject.email && errorObject.email !== "" ? (<div className="error">{errorObject.email}</div>) : null
                }
              </p>
              <p><span>Xác nhận Email*</span><input onChange={(e) => {
                const { value } = e.target
                onChangeData(value, 'vertifyEmail')
              }} type="text" value={data.vertifyEmail} placeholder="Nhập lại địa chỉ email ở trên" />
                {
                  errorObject && errorObject.vertifyEmail && errorObject.vertifyEmail !== "" ? (<div className="error">{errorObject.vertifyEmail}</div>) : null
                }
              </p>
              {
                data?.payment === "cod" &&
                <>
                <p><span>Địa chỉ nhận vé*<span className="sm">(Vui lòng ghi rõ số nhà, đường, phường/xã,quận/huyện và tỉnh thành)</span></span><input value={data.customer_address} onChange={(e) => {
                  const { value } = e.target
                  const newValue = value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                  onChangeData(newValue, 'customer_address')
                }} type="text" placeholder="Ví dụ : 102 Quang Trung, Gò Vấp, TP Hồ Chí Minh" />
                {
                  errorObject && errorObject.customer_address && errorObject.customer_address !== "" ? (<div className="error">{errorObject.customer_address}</div>) : null
                }
              </p>
               <div className="info_bank">
               <p className="img"><img src="./images/info.png" alt=""/></p>
               <div className="des">
                   <p><span className="bold green">Lưu ý:</span></p>
                   <p>• <span className="bold pink">Phí vận chuyển là 15.000đ</span>, vui lòng thực hiện thanh toán tiền vé và phí vận chuyển theo thông tin sau:<br />• TK ngân hàng: <span className="bold pink">Techcombank – Le Thi Bich Tram - 19031837041027</span><br />• Nội dung chuyển khoản: <span className="bold pink">[Họ tên – Số điện thoại – Số lượng vé]</span><br />➤ Ví dụ: <span className="bold pink">Nguyen Van A – 039 XX XX XX - 2</span><br />BTC sẽ phản hồi qua email bạn đã đăng ký trong vòng 02 ngày làm việc. Sau thời gian trên, nếu chưa nhận được thông tin, vui lòng liên hệ với BTC để được hỗ trợ.</p>
               </div>
              </div>
              </>
              }
              
            </div>
            <div className="popup_btn_container">
              <p onClick={handleChangeStep} className={`btn_accept`}>Quay lại</p>
              <p onClick={() => {
                if (validate()) {
                  handlePayment()
                }
              }} className={`btn_accept ${isPass ? "btn_next" : "btn_accept-"}`}>Xác nhận mua</p>

            </div>
          </div>
        </div>
      </div>
      <a ref={redirectRef} style={{ display: 'none' }} />
    </div>
    )
}

export default TicketPage